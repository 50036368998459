import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'nullReplace'
})
export class NullReplacePipe implements PipeTransform {

  transform(value: unknown, replace = 'false'): unknown {
    return value == null ? replace : value;
  }

}
