import {AvailableProviderResponse} from '../../../../../../../api/models/available-provider-response';

export interface AvailableProviderResponseExtended extends AvailableProviderResponse {
  displayName: string;
  isSelected: boolean | null;
  type: AccountingIntegrationType;
}

export enum AccountingIntegrationType {
  OAuth,
  CSV,
  Other
}
