/* tslint:disable */
/* eslint-disable */
export enum CurrentFeatureFlags {
  MasterSuppliers = 'MasterSuppliers',
  DeveloperTools = 'DeveloperTools',
  QuickBooks = 'QuickBooks',
  Myob = 'MYOB',
  DownloadAccountingData = 'DownloadAccountingData',
  DownloadActivityData = 'DownloadActivityData',
  QuotingV2 = 'QuotingV2',
  GrossEmissions = 'GrossEmissions',
  IndustryBenchmarking = 'IndustryBenchmarking'
}
