/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { badgeClick } from '../fn/badge-instance/badge-click';
import { BadgeClick$Params } from '../fn/badge-instance/badge-click';
import { BadgeInstanceMinimalResponse } from '../models/badge-instance-minimal-response';
import { BadgeInstanceResponse } from '../models/badge-instance-response';
import { getById } from '../fn/badge-instance/get-by-id';
import { GetById$Params } from '../fn/badge-instance/get-by-id';
import { publish } from '../fn/badge-instance/publish';
import { Publish$Params } from '../fn/badge-instance/publish';
import { search } from '../fn/badge-instance/search';
import { Search$Params } from '../fn/badge-instance/search';
import { update } from '../fn/badge-instance/update';
import { Update$Params } from '../fn/badge-instance/update';

@Injectable({ providedIn: 'root' })
export class BadgeInstanceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1BadgeInstanceSearchPost()` */
  static readonly V1BadgeInstanceSearchPostPath = '/v1/badge-instance/search';

  /**
   * Get instance by measurementProfileId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Response(params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<BadgeInstanceResponse>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * Get instance by measurementProfileId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search(params?: Search$Params, context?: HttpContext): Observable<BadgeInstanceResponse> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<BadgeInstanceResponse>): BadgeInstanceResponse => r.body)
    );
  }

  /** Path part for operation `v1BadgeInstanceUpdatePut()` */
  static readonly V1BadgeInstanceUpdatePutPath = '/v1/badge-instance/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update$Response(params?: Update$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return update(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update(params?: Update$Params, context?: HttpContext): Observable<void> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1BadgeInstanceGetByIdPost()` */
  static readonly V1BadgeInstanceGetByIdPostPath = '/v1/badge-instance/get-by-id';

  /**
   * Get active instance for the given measurement profile id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getById$Response(params?: GetById$Params, context?: HttpContext): Observable<StrictHttpResponse<BadgeInstanceMinimalResponse>> {
    return getById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get active instance for the given measurement profile id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getById(params?: GetById$Params, context?: HttpContext): Observable<BadgeInstanceMinimalResponse> {
    return this.getById$Response(params, context).pipe(
      map((r: StrictHttpResponse<BadgeInstanceMinimalResponse>): BadgeInstanceMinimalResponse => r.body)
    );
  }

  /** Path part for operation `v1BadgeInstancePublishPost()` */
  static readonly V1BadgeInstancePublishPostPath = '/v1/badge-instance/publish';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publish()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publish$Response(params?: Publish$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return publish(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publish$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  publish(params?: Publish$Params, context?: HttpContext): Observable<void> {
    return this.publish$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1BadgeInstanceBadgeClickPost()` */
  static readonly V1BadgeInstanceBadgeClickPostPath = '/v1/badge-instance/badge-click';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `badgeClick()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  badgeClick$Response(params?: BadgeClick$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return badgeClick(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `badgeClick$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  badgeClick(params?: BadgeClick$Params, context?: HttpContext): Observable<void> {
    return this.badgeClick$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
