/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EstimatedQuoteByScopeRequest } from '../../models/estimated-quote-by-scope-request';
import { EstimateResult } from '../../models/estimate-result';

export interface EstimateByScope$Params {
      body?: EstimatedQuoteByScopeRequest
}

export function estimateByScope(http: HttpClient, rootUrl: string, params?: EstimateByScope$Params, context?: HttpContext): Observable<StrictHttpResponse<EstimateResult>> {
  const rb = new RequestBuilder(rootUrl, estimateByScope.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<EstimateResult>;
    })
  );
}

estimateByScope.PATH = '/v1/payment/estimate-by-scope';
